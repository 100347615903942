"use client";

import '../background-animation/styles.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TweetEmbed from 'react-tweet-embed';
import InnerHTML from 'dangerously-set-html-content';
import TiktokEmbed from './tiktok-embed';
import { Tooltip } from 'react-tooltip';
import { Card, CardContent } from '../ui/card';
import { ErrorFeedback } from '../ui/error-feedback';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { MayarAlertBase } from '../ui/mayar-alert-base';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  copyToClipboardByValue,
  getLinkTreePathMayarURL,
  rootUrl,
  validator,
  parseJSON,
  linksType,
  createQueryStringFromObject,
  baseURL,
  isBooleanString,
  createWhatsappLink
} from '../../utils';
import { sanitizeWhatsAppLink, sanitizeDOM } from '../../utils/sanitizer';
import { INSERT_VISITOR_FORM_LANDING_PAGE } from '../../gqls';
import { GQL_CREATE_OR_GET_CUSTOMER } from '../../gqls/customers';
import SimpleLandingStyle from './styles';
import BackgroundAnimation from '../background-animation';
import { Icon } from '@iconify/react';
import SubmitButton from '../submit-button';
import Spinner from "../ui/spinner"
import ProductPopUp from './product';
import ComingSoon from '../coming-soon';
import CustomFormBuilder, { getCustomeField } from '../custom-form-builder';
import SupportComponent from './support';
import Catalog from './catalog';
import FAQS from './faq';

const UnitIconButton = ({ data }) => {
  const { iconType, iconImg, selectedEmoji } = data;

  if (iconType === 'emoji') {
    return <div className="icon_emoji_style">{selectedEmoji}</div>;
  } else if (iconType === 'image' && iconImg) {
    return <img className="icon_image_style" alt="icon" src={iconImg} width="40" />;
  }

  return <></>;
};

const LinkByType = ({ data, paymeLink, userData, username }) => {
  const { t } = useTranslation();
  const { type, title, bannerImageUrl, text, header, iframeHeight, fieldObject } = data || {};
  let { url } = data || {};

  const form = useForm();
  const { handleSubmit, register, formState } = form;
  const { errors } = formState;

  const [buttonCopy, setButtonCopy] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  const [message, setMessage] = useState();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [getOrCreateCustomer, { loading }] = useLazyQuery(GQL_CREATE_OR_GET_CUSTOMER, {
    context: { v2: true },
  });
  const [insertVisitorValue] = useMutation(INSERT_VISITOR_FORM_LANDING_PAGE, {
    context: { v2: true },
  });

  if (type === 'PRODUCT') {
    url = `${baseURL(userData.paymeLink)}${url}`;
  }

  if (type === 'CATALOG') {
    url = `${rootUrl(paymeLink)}${getLinkTreePathMayarURL(url)}`;
  }

  if (type === 'WHATSAPP') {
    url = createWhatsappLink(data?.mobile, data?.text);
  }

  const checkTitle = (name) => {
    if (name === 'Produk Digital') {
      return t('nav.digitalProduct');
    } else if (name === 'Produk Fisik') {
      return t('nav.physicalProduct');
    }

    return name;
  };

  const handleCopy = (value) => {
    copyToClipboardByValue(value);
    setButtonCopy(true);
    setTimeout(() => {
      setButtonCopy(false);
    }, 3000);
  };

  const handleSubscribe = async (values) => {
    const emailSplit = values.email.split('@');
    const nameByEmail = emailSplit[0];

    if (!nameByEmail || nameByEmail.length <= 0) {
      setErrorMessage('Invalid input.');
      return;
    }

    try {
      const res = await getOrCreateCustomer({
        variables: {
          email: values.email,
          name: nameByEmail,
          userId: userData?.id,
        },
      });

      if (res?.data?.getOrCreateCustomer?.message === 'Customer already subscribed.') {
        setMessage(res.data.getOrCreateCustomer.message);
      } else {
        setSubmitSuccess(true);
      }
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const handleSubmitForm = async () => {
    setLoadingForm(true);

    if (data?.redirectUrl) {
      const queryString = createQueryStringFromObject(
        getCustomeField()
          ?.filter((field) => field.parameterName)
          .map((field) => {
            if (field.type === 'string') return { ...field, value: sanitizeStrict(field.value) };
            return field;
          })
          .reduce((acc, field) => ({ ...acc, [field.parameterName]: field.value }), {}) || {},
      );
      window.open(data?.redirectUrl + `?${queryString}`, '_blank');
    }

    await insertVisitorValue({
      variables: {
        input: {
          landingPageId,
          formId: data?.id,
          data: JSON.stringify(
            getCustomeField()?.map((field) => {
              if (field.type === 'string') return { ...field, value: sanitizeStrict(field.value) };
              return field;
            }) || [],
          ),
        },
      },
    });

    setLoadingForm(false);
  };

  switch (type) {
    case 'TEXT':
      return <div dangerouslySetInnerHTML={{ __html: sanitizeDOM(text) }}></div>;
    case 'YOUTUBE':
      return (
        <iframe
          src={url}
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="youtube"
        />
      );
    case 'IFRAME':
      return (
        <iframe
          src={url}
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ width: `100%`, height: `${iframeHeight}px` }}
        />
      );
    case 'TIKTOK':
      return <TiktokEmbed url={url} />;
    case 'TWITTER':
      //https://twitter.com/@name/status/id
      const idObj = url?.split('status/')[1];
      const id = idObj?.split('?')[0];
      return <TweetEmbed id={id} />;
    case 'SPOTIFY':
      return (
        <iframe
          style={{ borderRadius: '15px' }}
          title={title}
          src={url}
          width="100%"
          height="380"
          frameBorder="0"
          allowFullScreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
      );
    case 'BANNER':
      return (
        <>
          {url ? (
            <a href={url} target="_blank" rel="noopener noreferrer nofollow">
              <img
                src={bannerImageUrl}
                alt={title}
                className="linktree__image--banner"
                style={data?.heightType === 'AUTO' ? { height: 'auto' } : {}}
              />
            </a>
          ) : (
            <img
              src={bannerImageUrl}
              alt={title}
              className="linktree__image--banner"
              style={data?.heightType === 'AUTO' ? { height: 'auto' } : {}}
            />
          )}
        </>
      );
    case 'SUBSCRIPTION':
      return (
        <Card className="linktree__link--card">
          <CardContent className='pt-4'>
            <h3 className="mb-[5px]">{text}</h3>
            <form>
              <MayarAlertBase
                variant="light"
                className="mb-[10px] bg-gray-200 text-dark"
                isOpen={message ? true : false}
                onCloseAlert={() => setMessage(false)}
              >
                {message}
              </MayarAlertBase>
              <MayarAlertBase
                variant="light"
                className="mb-[10px] bg-light text-danger"
                isOpen={errorMessage ? true : false}
                onCloseAlert={() => setErrorMessage(false)}
              >
                {errorMessage}
              </MayarAlertBase>

              <MayarAlertBase
                variant="light"
                className="mb-[10px] bg-gray-200 text-success"
                isOpen={submitSuccess}
                onCloseAlert={() => setSubmitSuccess(!submitSuccess)}
              >
                {submitSuccess && (
                  <div className='flex items-center'>
                    {`Subscribed`}
                    <Icon icon="material-symbols-light:check" className="ml-[5px]" width="24" height="24" /> <span className="font-semibold"></span> 
                  </div>
                )}
              </MayarAlertBase>

              <Label className="mb-[5px] mt-2 text-[0.9rem]">Email</Label>
              <div className="flex justify-center items-center w-full">
                <Input
                  type="email"
                  name="email"
                  placeholder="email@mail.com"
                  invalid={Boolean(errors.email)}
                  {...register("email", { required: validator.email })}
                />
                <SubmitButton
                  onClick={handleSubmit(handleSubscribe)}
                  loading
                  isLoading={loading}
                  className="ml-[10px] linktree__card--button"
                  text="Subscribe"
                />
              </div>
              <ErrorFeedback id="email-hint">{errors.email?.message}</ErrorFeedback>
            </form>
          </CardContent>
        </Card>
      );
    case 'COUPON':
      return (
        <Card className="linktree__link--card--dashed">
          <CardContent className="p-0">
            <Button
              id="promo"
              onClick={() => handleCopy(url)}
              data-tooltip-id="tooltip-promo-click"
              className="p-[20px] block linktree__card--button h-auto"
            >
              <h3 className="mb-0">{title}</h3>
              <div className="divider my-[10px]"></div>
              <div>Use Code {url}</div>
            </Button>
            <Tooltip
              id="tooltip-promo-click"
              content="Copied!"
              events={['click']}
            />
          </CardContent>
        </Card>
      );
    case 'PRODUCT':
      return (
        // <>
        //   <ProductPopUp
        //     id={url} username={paymeLink} userId={userData?.id}
        //   >
        //     <Button
        //       onClick={() => setPopUp(true)}
        //       className="w-full linktree__link--button flex
        //     justify-center content-center p-auto h-auto relative"
        //     >
        //       <span className="icon__support absolute left-4 text-3xl" role="img">
        //         <UnitIconButton data={data}></UnitIconButton>
        //       </span>
        //       <p className="my-auto text-center">{checkTitle(title)}</p>
        //     </Button>
        //   </ProductPopUp>
        // </>
        <InnerHTML
          html={`
            <iframe allowfullscreen="" allowpaymentrequest="allowpaymentrequest" scrolling="no" frameborder="0" width="100%" height="100%" src="${url}" data-layout-mode="SIMPLE"></iframe>
            <script type="text/javascript" src="https://mayarembed.r2.mayar.id/mayarEmbed.min.js"></script>
          `}
        />
      );
    case 'SUPPORT':
      return (
        <SupportComponent
          id={userData?.id}
          name={userData?.account?.name}
          payChannelConfig={userData?.payChannelConfig}
          username={username}
        />
      );
    case 'HTML':
      return text ? <InnerHTML html={text} /> : null;
    case 'FORM':
      return (
        <form className="form-container" onSubmit={handleSubmit(handleSubmitForm)}>
          <h2>{header}</h2>
          <p>{text}</p>
          <div className="flex flex-col gap-[15px]">
            <CustomFormBuilder
              form={{ fieldObject: JSON.stringify(fieldObject) }}
              formState={form}
              isLabelRequired
            />
          </div>
          <SubmitButton
            size="lg"
            loading
            isLoading={loadingForm}
            className="w-fit"
            text={data?.buttonText}
          />
        </form>
      );
    case 'SOCIAL_MEDIA_ICON':
      return (
        <div className="linktree__social--links">
          {fieldObject.map((item) => {
            if (!item.url) return;

            const fileName = item.type.toLowerCase();
            const urlSocialMedia =
              item.type === 'WHATSAPP' ? sanitizeWhatsAppLink(item.url) : item.url;
            return (
              <a
                href={urlSocialMedia}
                target="_blank"
                rel="noopener noreferrer nofollow"
                key={item.type}
              >
                <img
                  className="linktree__social--links__logo"
                  width="22"
                  src={`https://assets.mayar.id/${fileName}.svg`}
                  alt={fileName}
                />
              </a>
            );
          })}
        </div>
      );
    case 'COUNTDOWN':
      return <ComingSoon startDate={data?.startedAt} customMessage={title} isRemoveTopLine />;
    case 'DIVIDER':
      return (
        <div className="linktree__links--item--divider" style={{ height: `${data?.height}px` }}>
          {data?.dividerType === 'SOLID' && (
            <hr className="linktree__links--item--divider--hr"></hr>
          )}
          {data?.dividerType === 'DASHED' && (
            <hr className="linktree__links--item--divider--hr dashed"></hr>
          )}
        </div>
      );
    case 'FAQ':
      return <FAQS faqs={data?.fieldObject} header={data?.header} />;
    default:
      return (
        <a
          href={url}
          target={data?.linkType === 'ANCHOR' ? '_self' : '_blank'}
          rel="noopener noreferrer nofollow"
          className={`linktree__link--button d-flex justify-content-center align-content-center linktree__link--button--${data.id}`}
        >
          <span className="icon__support !mr-auto" role="img">
            <UnitIconButton data={data}></UnitIconButton>
          </span>
          <p className="!mr-auto">{checkTitle(title)}</p>
        </a>
      );
  }
};

const SimpleLandingPage = ({ data, loading, isPreview, userData, landingPageId, username }) => {
  const linksState = data.links ? JSON.parse(data.links) : [];
  const { backgroundAnimationId = '' } = data.background ? JSON.parse(data.background) : {};
  const backgroundClassName = backgroundAnimationId?.split(' ').join('-').toLowerCase();

  // resize iframe
  useEffect(() => {
    if (!window.iFrameResize) return;
    const iframes = window.iFrameResize();
    iframes.forEach(iframe => {
      const dataset = iframe.dataset || {};
      if (dataset?.layoutMode === 'SIMPLE') {
        iframe.iFrameResizer.resize();

        const interval = setInterval(
          () => {
            if (iframe.contentWindow) {
              clearInterval(interval);
              iframe.contentWindow.postMessage(
                {
                  target: "mayar-checkout-embed",
                  data: Object.assign(
                    {},
                    ...Object.keys(dataset).map((k) => ({
                      [k]: isBooleanString(dataset[k]) ? Boolean(dataset[k]) : dataset[k],
                    }))
                  ),
                },
                "*"
              );
            }
          }, 100
        );
      }
    });
  }, [data]);

  return (
    <SimpleLandingStyle className={backgroundClassName} isPreview={isPreview} data={data}>
      {isPreview && loading && (
        <div className="w-full h-full flex absolute pl-[20px] pt-[20px] z-[1000]">
          <Spinner className="text-secondary" />
        </div>
      )}
      {backgroundAnimationId && <BackgroundAnimation id={backgroundAnimationId} />}
      <div className={`linktree__container ${isPreview ? '' : 'p-0'}`}>
        <div className="linktree__wrap">
          <main className="linktree__main--content">
            <div className="linktree__links">
              {linksState.map((objValue) => {
                const { id, title, url, type } = objValue;
                if (
                  (title === '' || url === '') &&
                  type !== 'TEXT' &&
                  type !== 'SUBSCRIPTION' &&
                  type !== 'COUPON' &&
                  type !== 'SUPPORT' &&
                  type !== 'HTML' &&
                  type !== 'SOCIAL_MEDIA_ICON' &&
                  type !== 'WHATSAPP' &&
                  type !== 'COUNTDOWN' &&
                  type !== 'DIVIDER' &&
                  type !== 'FORM' &&
                  type !== 'BANNER' &&
                  type !== 'FAQ'
                ) {
                  return;
                }

                return (
                  <div
                    id={id}
                    className={`linktree__links--item linktree__links--item--${id} ${
                      type === 'YOUTUBE' ? 'linktree__links--item--iframe' : ''
                    } ${type === 'TIKTOK' ? 'linktree__links--item--iframe--tiktok' : ''} `}
                    key={id}
                  >
                    <LinkByType data={objValue} userData={userData} landingPageId={landingPageId} username={username} />
                  </div>
                );
              })}
            </div>
          </main>
        </div>
      </div>
    </SimpleLandingStyle>
  );
};

SimpleLandingPage.defaultProps = {
  data: {},
  userData: {},
  loading: false,
  isPreview: false,
};

SimpleLandingPage.propTypes = {
  data: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  isPreview: PropTypes.bool,
};

export default SimpleLandingPage;
