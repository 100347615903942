"use client";

import React, { useState } from 'react';
import { get } from 'lodash';
import { GQL_GET_SUPPORT_BY_LINK, GQL_CREATE_PAYMENTTRANSACTION_SUPPORT } from '../../gqls';
import { Card, CardContent } from '../ui/card';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Button } from '../ui/button';
import { decrypt, encrypt, formatRp, capitalizeFirstLetter } from '../../utils';
import { useSearchParams, useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import { useQuery as useApolloQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { OneClick } from '../forms-new';
import SubmitButton from '../submit-button';
import { Icon } from '@iconify/react';
import Cookies from 'js-cookie';
import { UnitIcon } from '../pages/SupportPage/components/unit-icon';
import MayarAlert from '../alert/alert';

const SupportComponent = ({ id, name: accName, payChannelConfig, username }) => {
  const { t } = useTranslation();
  const search = useSearchParams();
  const subdomainLink = username;
  const form = useForm();
  const { handleSubmit, register, formState } = form;
  const { errors } = formState;
  const history = useRouter();

  const [unitCount, setUnitCount] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [amountProduct, setAmountProduct] = useState(Number(search.get('amount')));
  const [submitError, setSubmitError] = useState(false);

  const { data: supportData, loading: supportLoading } = useApolloQuery(GQL_GET_SUPPORT_BY_LINK, {
    context: { public: true },
    variables: { link: subdomainLink },
  });

  const [
    createPaymentTransactionSupport,
    { loading: submitLoading, data: pltData },
  ] = useMutation(GQL_CREATE_PAYMENTTRANSACTION_SUPPORT, { context: { public: true } });

  const sd = get(supportData, 'getSupportByLinkPublic');
  const plt = get(pltData, 'createPaymentTransactionSupport');

  const unitIcon = sd?.unitIcon ? JSON.parse(sd.unitIcon) : [];

  if (plt?.id) {
    if (selectedPayment?.path.includes('OVO')) {
      // history.push(`/plt/${plt?.id}/OVO${search && `?${search.toString()}`}`);
      history.push(`/pay/${plt?.id}${search && `?${search.toString()}`}`);
    } else if (plt?.xenditId) {
      // history.push(`/plt/${plt?.id}/${selectedPayment?.path}/${plt?.xenditId}${search && `?${search.toString()}`}`);
      history.push(`/pay/${plt?.id}${search && `?${search.toString()}`}`);
    }
  }

  const defaultCustomerCookies = {
    ...(Cookies.get('customerInput') ? decrypt(Cookies.get('customerInput')) : {}),
    ...(search.get('name') && { name: search.get('name') }),
    ...(search.get('email') && { email: search.get('email') }),
    ...(search.get('mobile') && { mobile: search.get('mobile') }),
    ...(search.get('amount') && { amount: search.get('amount') }),
    ...(search.get('message') && { message: search.get('message') }),
  };

  const onSubmit = async (values) => {
    let submitValues = { ...values };

    if (defaultCustomerCookies?.isOneClick) {
      delete defaultCustomerCookies.amount;
      delete defaultCustomerCookies.description;

      if (values.email) {
        submitValues = { ...defaultCustomerCookies, ...values };
      } else {
        submitValues = { ...values, ...defaultCustomerCookies };
      }
    }

    const { name, mobile, description, message } = submitValues;
    const email = submitValues?.email?.toLowerCase();
    const formattedMobile = mobile.replace(/\D/g, '');

    try {
      const res = await createPaymentTransactionSupport({
        variables: {
          input: {
            paymentLinkName: 'Support',
            email,
            name,
            mobile: formattedMobile,
            description,
            userId: id,
            paymentType: selectedPayment?.path,
            message,
            unit: parseInt(unitCount),
          },
        },
      });

      if (!res.data?.createPaymentTransactionSupport?.xenditId) {
        throw new Error('Cannot create Transaction');
      }

      Cookies.set(
        'customerInput',
        encrypt({ ...submitValues, paymentData: selectedPayment, isOneClick: true }),
        {
          secure: true,
          expires: 30,
          domain: process.env.NEXT_PUBLIC_BASE_URL,
        },
      );
    } catch (err) {
      setSubmitError(true);
    }
  };

  const parentState = {
    finalAmount: amountProduct,
    selectedPayment,
    amountProduct,
    defaultCustomerCookies,
  };

  const handleUnit = (number) => {
    setUnitCount(number);
    setAmountProduct(number * sd?.unitValue);
  };

  if (supportLoading) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <Icon icon="svg-spinners:270-ring-with-bg" className="text-2xl" />
      </div>
    );
  }

  return (
    <Card className="support_card wrapper__konten shadow__bx b-12 border-0 mr-auto">
      <CardContent className='p-0'>
        {submitError && (
          <div className="mb-[20px] w-full">
            <MayarAlert isError={submitError} className="support-alert" />
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className="form rui-sign-form rui-sign-form-cloud w-full !max-w-full !bg-inherit">
          <div className="vertical-gap sm-gap justify-center">
            <div className='w-full'>
              <h1 className="font-semibold mb-0 text-center text-[1.2rem]">{`${t('support.buy')} ${accName} ${t(
                'support.a',
              )} ${capitalizeFirstLetter(sd?.unit || '')} ${t('support.for')}`}</h1>
              <div className="flex justify-around items-center max-w-[400px] mx-auto my-4">
                <div className="flex items-center mr-[5px]">
                  <span className="icon__support" role="img">
                    <UnitIcon unitIcon={unitIcon} />
                  </span>
                  <span className="my-auto mr-5">
                    <Icon icon="maki:cross" className="text-xl" />
                  </span>
                </div>
                <Button
                  onClick={() => handleUnit(1)}
                  className="support_button number__support mr-[5px]"
                >
                  1
                </Button>
                <Button
                  onClick={() => handleUnit(3)}
                  className="support_button number__support mr-[5px]"
                >
                  3
                </Button>
                <Button
                  onClick={() => handleUnit(5)}
                  className="support_button number__support mr-[5px]"
                >
                  5
                </Button>
                <Input
                  type="number"
                  name="amount"
                  id="amount"
                  value={unitCount}
                  onChange={(e) => handleUnit(e.target.value)}
                  invalid={Boolean(errors.amount)}
                  {...register("amount")}
                  className="input__number text-center"
                  min={1}
                  max={500}
                ></Input>
              </div>
            </div>

            <div className='w-full'>
              <Textarea
                type="textarea"
                name="message"
                id="message"
                className="mb-[20px] form-control form-control-lg support-message !min-h-[10rem]"
                placeholder="Say something nice.. (optional)"
                {...register("message")}
              ></Textarea>
            </div>

            <h5 className="mb-0 text-center">
              <strong>{t('support.detail')}</strong>
            </h5>

            <OneClick
              useForm={() => form}
              onPaymentChange={setSelectedPayment}
              parentState={parentState}
              paymentLink={{ type: 'support', payChannelConfig: payChannelConfig }}
            />

            <div className="text-center w-[100%] my-2">
              <SubmitButton
                block
                color="brand"
                type="submit"
                className="support_button text-center w-[99%]"
                loading
                isLoading={submitLoading}
                isDisabled={amountProduct < 10000 || amountProduct > 500_000_000}
                text={`${t('word.support')} ${formatRp(amountProduct)}`}
                loadingMessage={t('global.checkQueue')}
              />
            </div>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

export default SupportComponent;
