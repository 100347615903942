import './style.scss';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../ui/input';
import { Card } from '@/components/ui/card';
import { formatRp, onlyUniqueObjectOfArray } from '../../utils';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
import { UseFormReturn } from 'react-hook-form';
import { Icon } from "@iconify/react";
import { ITicket } from "@/composables/ticket.type"
import { IPaymentLink } from '@/composables/paymentlink.type';
import { RemainingStock } from '@/common/types/ticket.type';
import { cn } from '@/lib/utils';
import { Button } from '../ui/button';

type TicketShapeType = {
  onTicketSelected: React.Dispatch<React.SetStateAction<ITicket[]>>;
  data: ITicket & { isTemporarySoldOut?: string };
  eventHistory: RemainingStock;
  isDisabled: boolean;
  paymentLink: IPaymentLink;
  formState: UseFormReturn<Record<string, number>>
  invalid: boolean;
  isDiscountUsed: boolean;
}

const TicketShape: React.FC<TicketShapeType> = ({
  onTicketSelected,
  data,
  eventHistory,
  isDisabled,
  paymentLink,
  formState,
  invalid,
  isDiscountUsed,
}) => {
  const { t } = useTranslation();

  const [quantity, setQuantity] = useState(0);

  const { register } = formState;
  const {
    id,
    name,
    amount,
    ticketEndDate,
    ticketStartDate,
    description,
    isVisible,
    quantity: stockTicket,
  } = data;

  const currentDate = new Date();
  const totalSold = eventHistory?.detailEvent?.find((obj) => obj.id === id)?.qty || 0;
  const actualStock = stockTicket - totalSold;

  useEffect(() => {
    onTicketSelected((prevState) =>
      onlyUniqueObjectOfArray(
        [
          {
            id,
            qty: Number(quantity),
            name,
          },
          ...prevState,
        ],
        'id',
      ),
    );
  }, [quantity]);

  const checkMaxQuantity = () => {
    let totalLimit = paymentLink?.event?.maxTicket;

    if (actualStock < paymentLink?.event?.maxTicket) {
      totalLimit = actualStock;
    }

    return quantity === totalLimit ? 'disable' : 'clickable';
  };

  const displayCounter = () => {
    if (data?.isTemporarySoldOut) {
      return <h4 className="text-red-500">Waiting List</h4>;
    }

    // Check ticket quantity
    const addQuantity = () => setQuantity(quantity + 1);
    const reduceQuantity = () => setQuantity(quantity - 1);

    if (actualStock > 0) {
      if (isDisabled || !isVisible || isDiscountUsed) {
        return '';
      } else if (ticketEndDate && new Date(ticketEndDate) > currentDate) {
        // Check ticket expired time
        return (
          <div className="ticket__counter">
            <button
              type="button"
              onClick={reduceQuantity}
              className={cn(
                'py-[7px] px-[12px] w-[39.38px] h-[37.67px] rounded-[2px]',
                quantity === 0 ? 'disable' : 'clickable'
              )}
            >
              <Icon icon="ph:minus" width="16" height="16" />
            </button>
            <Input
              id={`multiple-ticket-${id}`}
              type="number"
              readOnly
              className="ticket--number text-[1rem] text-black h-[37.67px] rounded-[3px] focus-visible:!ring-0 focus-visible:!ring-offset-0"
              {...register(`multiple-ticket-${id}`)}
              value={quantity}
              disabled
            />
            <button
              type="button"
              onClick={addQuantity}
              className={`
                ${checkMaxQuantity()} py-[7px] px-[12px] w-[39.38px] h-[37.67px] rounded-[2px]
              `}
            >
              <Icon icon="ph:plus" width="16" height="16" />
            </button>
          </div>
        );
      } else if (!ticketEndDate) {
        return (
          <div className="ticket__counter">
            <button
              type="button"
              onClick={reduceQuantity}
              className={cn(
                'py-[7px] px-[12px] w-[39.38px] h-[37.67px] rounded-[2px]',
                quantity === 0 ? 'disable' : 'clickable'
              )}
            >
              <Icon icon="ph:minus" width="16" height="16" />
            </button>
            <Input
              id={`multiple-ticket-${id}`}
              type="number"
              readOnly
              className="ticket--number h-[37.67px] rounded-[3px] focus-visible:!ring-0 focus-visible:!ring-offset-0"
              {...register(`multiple-ticket-${id}`)}
              value={quantity}
              disabled={isDisabled || !isVisible || isDiscountUsed}
            />
            <button
              type="button"
              onClick={addQuantity}
              className={cn(
                'py-[7px] px-[12px] w-[39.38px] h-[37.67px] rounded-[2px]',
                checkMaxQuantity()
              )}
            >
              <Icon icon="ph:plus" width="16" height="16" />
            </button>
          </div>
        );
      }
    }

    // Handle sold out
    // in case sold out ticket more than ticket stock
    if (actualStock <= 0) {
      return <h4 className="text-red-500">{t('word.soldOut')}</h4>;
    }
  };

  const Expired = () => {
    if (ticketEndDate && currentDate > new Date(ticketEndDate)) {
      return <span className="ticket--badge ticket--badge--success">{t('event.end')}</span>;
    }

    return '';
  };

  const StartDate = () => {
    if (currentDate < new Date(ticketStartDate)) {
      return <span className="ticket--badge ticket--badge--danger">{t('form.notStarted')}</span>;
    }

    return '';
  };

  return (
    <div className={`ticket ${invalid ? 'is-invalid' : ''}`}>
      <div className="ticket__left">
        <span className="ticket__circle circle--bottom">
          <span className="line"></span>
        </span>
        <span className="ticket__circle circle--top">
          <span className="line"></span>
        </span>
      </div>
      <div className="ticket__content">
        <div className="ticket--title">
          <h4 className="ticket--name">{name}</h4>
          <h4>
            <StartDate />
            <Expired />
          </h4>
        </div>

        <hr className='my-2' />
        {description && (
          <>
            <Accordion type="single" collapsible className="w-full outline-none focus:outline-none">
              <AccordionItem value="item-1" className='border-b-0 focus:outline-none'>
                <AccordionTrigger className='py-1'>
                  <i className="cursor-pointer text-[13px] italic font-open-sans font-[400]" id={`toggler${id}`}>
                    {t('form.ticketDesc')}
                  </i>
                </AccordionTrigger>
                <AccordionContent className='pb-0'>
                  <Card className="rounded-sm shadow-none">
                    <div className="py-2 px-2 bg-white border-none text-[13px]">{description}</div>
                  </Card>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </>
        )}

        <div className="ticket__content__bottom !items-start">
          <h4 className="ticket--price">
            <b>
              {amount > 0 ? (
                formatRp(amount)
              ) : (
                <span className="text-green-500">{t('word.free')}</span>
              )}
            </b>
          </h4>
          {currentDate > new Date(ticketStartDate) && displayCounter()}
        </div>
      </div>
    </div>
  );
};

export default TicketShape;
