"use client";

import "./style.scss";
import React, { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import he from "he";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { sanitizeDOM } from "@/utils/sanitizer";
import { cn } from "@/lib/utils";
import { isHTML as isStringHTML } from "@/utils";
// const isStringHTML = dynamic(
//   () =>
//     import("@/utils").then(
//       (el) => el.isHTML
//     ),
//   { ssr: false }
// );

const ReadMore = ({
  description = "",
  limit = 250,
  isHTML = false,
  className = "",
  useNormalParagraph = false,
  isNoWhiteGradient = false,
  isCanReadLess = false,
  toggleTextAlign = "center",
}) => {
  const { t } = useTranslation();
  const [expand, setExpand] = useState(false);
  const [filteredDescription, setFileteredDescription] = useState(description);

  const toggle = () => setExpand(!expand);

  useEffect(() => {
    if (!expand && description.length > limit) {
      setFileteredDescription(description.slice(0, limit));
    } else {
      setFileteredDescription(description);
    }
  }, [expand, description, limit]);

  if (isHTML && isNoWhiteGradient) {
    return (
      <>
        {he.decode(description.replace(/(<([^>]+)>)/gi, "")).length < limit ? (
          <div
            className={cn(
              "break-words w-full text-gray-600 text-[14.3px] mayar-text-description font-[300] reset-tailwind-override-htmltag",
              isStringHTML(description) ? "desc-editor" : "white-space-enabled",
              className
            )}
            dangerouslySetInnerHTML={{
              __html: sanitizeDOM(description),
            }}
          />
        ) : (
          <>
            <div
              className={cn(
                "break-words w-full text-gray-600 text-[14.3px] mayar-text-description font-[300] reset-tailwind-override-htmltag",
                isStringHTML(filteredDescription)
                  ? "desc-editor"
                  : "white-space-enabled",
                className
              )}
              dangerouslySetInnerHTML={{
                __html: sanitizeDOM(
                  expand ? filteredDescription : `${filteredDescription}...`
                ),
              }}
            />
            {!expand && description.length > limit && (
              <>
                <span
                  onClick={toggle}
                  onKeyDown={toggle}
                  className="cursor-pointer text-[#2980b9] text-[14.3px]"
                  aria-hidden="true"
                >
                  + {t("global.readMore")}
                </span>
              </>
            )}
            {expand && description.length > limit && isCanReadLess && (
              <span
                onClick={toggle}
                onKeyDown={toggle}
                className="cursor-pointer text-[#2980b9] text-[14.3px]"
                aria-hidden="true"
              >
                - {t("global.readLess")}
              </span>
            )}
          </>
        )}
      </>
    );
  }

  if (isHTML) {
    return (
      <>
        {he.decode(description.replace(/(<([^>]+)>)/gi, "")).length < limit ? (
          <div
            className={cn(
              "break-words w-full text-gray-600 text-[14.3px] mayar-text-description font-[300] reset-tailwind-override-htmltag",
              isStringHTML(description) ? "desc-editor" : "white-space-enabled",
              className
            )}
            dangerouslySetInnerHTML={{
              __html: sanitizeDOM(description),
            }}
          />
        ) : (
          <div className={expand ? "read-more expanded font-open-sans" : "read-more font-open-sans "}>
            <div className={`content minh-none ${expand ? "maxh-none" : ""}`}>
              <div
                className={cn(
                  "break-words w-full text-gray-600 mayar-text-description reset-tailwind-override-htmltag text-[14px]",
                  isStringHTML(description)
                    ? "desc-editor"
                    : "white-space-enabled",
                  className
                )}
                dangerouslySetInnerHTML={{
                  __html: sanitizeDOM(description),
                }}
              />
            </div>
            {!expand && (
              <div className={`text-${toggleTextAlign}`}>
                <span
                  className="trigger text-[#2980b9] text-[13px]"
                  onClick={toggle}
                  onKeyDown={toggle}
                  role="button"
                  tabIndex="0"
                >
                  +{" "}
                  {t(`global.readMore${toggleTextAlign === "left" ? "2" : ""}`)}
                </span>
              </div>
            )}
            {expand && description.length > limit && isCanReadLess && (
              <div className={`text-${toggleTextAlign}`}>
                <span
                  onClick={toggle}
                  onKeyDown={toggle}
                  className="cursor-pointer read-more-span text-[13px]"
                  aria-hidden="true"
                >
                  - {t("global.readLess")}
                </span>
              </div>
            )}
          </div>
        )}
      </>
    );
  }

  if (useNormalParagraph) {
    return (
      <p className={className}>
        {filteredDescription}
        {!expand && description.length > limit && (
          <>
            ...{" "}
            <span
              onClick={toggle}
              onKeyDown={toggle}
              className="cursor-pointer text-[#2980b9] text-[14.3px]"
              aria-hidden="true"
            >
              + {t("global.readMore")}
            </span>
          </>
        )}
      </p>
    );
  }

  return (
    <h5 className={`white-space-enabled ${className}`}>
      {filteredDescription}
      {!expand && description.length > limit && (
        <>
          ...{" "}
          <span
            onClick={toggle}
            onKeyDown={toggle}
            className="cursor-pointer text-[#2980b9] text-[14.3px]"
            aria-hidden="true"
          >
            + {t("global.readMore")}
          </span>
        </>
      )}
      {expand && isCanReadLess && (
        <div className={`text-${toggleTextAlign}`}>
          <span
            onClick={toggle}
            onKeyDown={toggle}
            className="cursor-pointer text-[#2980b9] text-[14.3px]"
            aria-hidden="true"
          >
            - {t("global.readLess")}
          </span>
        </div>
      )}
    </h5>
  );
};

// ReadMore.defaultProps = {
//   description: '',
//   limit: 250,
//   isHTML: false,
//   className: '',
//   isNoWhiteGradient: false,
//   useNormalParagraph: false, // this is written after revamp style for mobile and made so it doesn't break other component that already uses thisi component default style
// };

ReadMore.propTypes = {
  description: PropTypes.string.isRequired,
  limit: PropTypes.number,
  isHTML: PropTypes.bool,
  className: PropTypes.string,
  isNoWhiteGradient: PropTypes.bool,
  useNormalParagraph: PropTypes.bool,
};

export default ReadMore;
