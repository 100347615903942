"use client";

import React, { useState } from 'react';
import { Icon } from '@iconify/react';

const FAQ = ({ faq, isLastFAQ }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <button onClick={toggle}>
      <div className="faq-question">
        <Icon icon="line-md:plus" />
        <h3 className="text-left">{faq.question}</h3>
      </div>
      {isOpen && (
        <div className={`faq-answer ${isLastFAQ ? 'last' : ''}`}>
          <Icon icon="basil:cross-outline" />
          <p className="text-left">{faq.answer}</p>
        </div>
      )}
    </button>
  );
};

const FAQS = ({ faqs, header }) => {
  return (
    <div className="faq-container">
      <h2>{header}</h2>
      <div className="faq-content">
        {faqs.map((faq, index) => (
          <FAQ key={index} faq={faq} isLastFAQ={index === faqs.length - 1} />
        ))}
      </div>
    </div>
  );
};

export default FAQS;
